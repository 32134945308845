<template>
  <Protocols image="403" title="Acesso negado" />
</template>

<script>
export default {
  components: {
    Protocols: () => import('@/components/protocols')
  }
};
</script>
